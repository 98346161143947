<template>
  <div class="page">
    <div class="page-body">
      <div class="mb-2">
        <img width="120" src="~@/assets/img/index_menu_m3.png" alt="" />
      </div>
      <span v-for="(item, index) in message" :key="index"> {{ item }} <br /> </span>
      <h6>您的報價單已成立，感謝您的使用。</h6>
      <button type="button" class="btn btn-warning mt-4" @click="goSelectCustomer">
        回首頁
      </button>
    </div>
  </div>
</template>
<script>
/* global $ */
export default {
  name: "Finish",
  data() {
    return {
      message: ""
    };
  },

  methods: {
    // 回首頁
    goSelectCustomer() {
      localStorage.removeItem("CUSTOMER_QUOTE");
      localStorage.removeItem("QUOTATION");
      localStorage.removeItem("QUOTATION_MESSAGE");
      this.$router.push({ path: "select-customer" });
    }
  },

  created() {
    this.message = [...localStorage.QUOTATION_MESSAGE.split("。")];

    if (window.history && window.history.pushState) {
      $(window).on("popstate", function() {
        window.history.pushState("forward", null, document.URL);
        window.history.forward(1);
      });
    }
    window.history.pushState("forward", null, document.URL); //在IE中須有這兩行
    window.history.forward(1);
  }
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  .page-body {
    text-align: center;
    span {
      color: red;
      font-weight: 700;
      font-size: 16px;
    }
  }
}
</style>
